// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application"
import "controllers"
import { Tooltip, Popover } from 'bootstrap';

// Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
require("jquery")
require("jquery-ui")
require("webpack-jquery-ui/datepicker")
require('webpack-jquery-ui/css');
require('jquery-ui/ui/i18n/datepicker-de');