import { Controller } from "stimulus"

// for bootstrap 4 file input
// import bsCustomFileInput from 'bs-custom-file-input'

export default class extends Controller {
  connect() {
    $('.datepicker').datepicker({
      changeMonth: true,
      changeYear: true
    });
  }
}